import React, { useState } from 'react'
import { Icon, Button } from '@damen/ui'
import SkeletonLoader from '@components/UI/SkeletonLoader'
import { AssetDetailsProps } from './types'
import {
	DataListDescription,
	DataListDescriptionSmall,
	DataListGroup,
	DataListHeading,
	DataListTitle,
	DataListTitleSmall,
	Details,
	DetailsBottom,
	DetailsTop,
	MobileToggle,
	Wrapper
} from './styles'

const AssetDetails: React.FC<AssetDetailsProps> = ({
	blok,
	details,
	loading
}) => {
	const [toggled, setToggled] = useState(false)
	const {
		warrantyTitle,
		warrantyExpirationDateTitle,
		warrantyStatusTitle,
		warrantyTypeTitle,
		yardNumber: yardNumberTitle,
		type: typeTitle,
		delivered,
		hasWarranty: hasWarrantyLabel,
		hasNoWarranty,
		hideInformation,
		showInformation
	} = blok

	const returnDataList = (label: string, value: string) => (
		<dl>
			<DataListTitle>{label}</DataListTitle>
			<DataListDescription>{value}</DataListDescription>
		</dl>
	)

	if (loading) {
		const dls1 = [1, 2, 3]
		const dls2 = [4, 5, 6]

		return (
			<Wrapper>
				<Details>
					<DetailsTop>
						<DataListHeading>
							<SkeletonLoader style={{ opacity: 0.15 }} />
						</DataListHeading>
						<dl>
							{dls1.map((item) => {
								return (
									<DataListGroup key={item}>
										<DataListTitleSmall>
											<SkeletonLoader
												style={{ opacity: 0.15 }}
											/>
										</DataListTitleSmall>
										<DataListDescriptionSmall>
											<SkeletonLoader
												style={{ opacity: 0.15 }}
											/>
										</DataListDescriptionSmall>
									</DataListGroup>
								)
							})}
						</dl>
					</DetailsTop>
					<DetailsBottom>
						{dls2.map((item) => {
							return (
								<dl key={item}>
									<DataListTitle>
										<SkeletonLoader
											style={{ opacity: 0.15 }}
										/>
									</DataListTitle>
									<DataListDescription>
										<SkeletonLoader
											style={{ opacity: 0.15 }}
										/>
									</DataListDescription>
								</dl>
							)
						})}
					</DetailsBottom>
				</Details>
			</Wrapper>
		)
	}

	if (details) {
		const {
			hasWarranty,
			vesselType,
			deliveryDateFormatted,
			yardNumber,
			warrantyType,
			warrantyEndDateFormatted
		} = details
		const warranty = hasWarranty ? hasWarrantyLabel : hasNoWarranty

		return (
			<Wrapper>
				<MobileToggle toggled={toggled}>
					<Button.Default
						text={toggled ? hideInformation : showInformation}
						onClick={() => setToggled(!toggled)}
						colorType="blue"
						buttonType="text"
						isSmall
						icon={Icon.LeftChevron}
					/>
				</MobileToggle>
				<Details toggled={toggled}>
					<DetailsTop>
						{delivered &&
							deliveryDateFormatted &&
							returnDataList(delivered, deliveryDateFormatted)}
						{yardNumberTitle &&
							yardNumber &&
							returnDataList(yardNumberTitle, yardNumber)}
						{typeTitle &&
							vesselType?.id &&
							returnDataList(typeTitle, vesselType?.id)}
					</DetailsTop>
					<DetailsBottom>
						<DataListHeading>{warrantyTitle}</DataListHeading>
						<dl>
							{warrantyStatusTitle && warranty && (
								<DataListGroup>
									<DataListTitleSmall>
										{warrantyStatusTitle}
									</DataListTitleSmall>
									<DataListDescriptionSmall>
										{warranty}
									</DataListDescriptionSmall>
								</DataListGroup>
							)}
							{warrantyTypeTitle && warrantyType && (
								<DataListGroup>
									<DataListTitleSmall>
										{warrantyTypeTitle}
									</DataListTitleSmall>
									<DataListDescriptionSmall>
										{warrantyType}
									</DataListDescriptionSmall>
								</DataListGroup>
							)}
							{warrantyExpirationDateTitle &&
								warrantyEndDateFormatted && (
									<DataListGroup>
										<DataListTitleSmall>
											{warrantyExpirationDateTitle}
										</DataListTitleSmall>
										<DataListDescriptionSmall>
											{warrantyEndDateFormatted}
										</DataListDescriptionSmall>
									</DataListGroup>
								)}
						</dl>
					</DetailsBottom>
				</Details>
			</Wrapper>
		)
	}

	return null
}

export default AssetDetails

import { theme } from '@damen/ui'
import styled from 'styled-components'
import { hexToRgb } from '@utils/helper'
import { Toggled } from './types'

export const Wrapper = styled.div`
	margin-bottom: 24px;

	@media ${theme.legacyMediaQueries.md} {
		margin-bottom: 48px;
	}
`

export const Details = styled.div<Toggled>`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding-bottom: ${({ toggled }) => (toggled ? '48px' : '0')};
	border-bottom: ${({ toggled }) =>
		toggled
			? `1px solid ${hexToRgb(theme.colors.white, {
					alpha: 0.4,
					format: 'css'
			  })}`
			: 'none'};
	max-height: ${({ toggled }) => (toggled ? '1000px' : '0')};
	overflow: hidden;
	transition: 0.2s ease-in-out;

	@media ${theme.legacyMediaQueries.md} {
		max-height: none;
		padding-bottom: 48px;
		border-bottom: 1px solid
			${hexToRgb(theme.colors.white, {
				alpha: 0.4,
				format: 'css'
			})};
	}
`

export const DataListGroup = styled.div`
	width: calc(50% - ${theme.spacing.x1}px);

	@media ${theme.legacyMediaQueries.md} {
		width: calc(33.33% - ${theme.spacing.x2}px);
	}
`

export const DetailsBottom = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	width: 100%;

	dl {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		width: 100%;
		gap: ${theme.spacing.x2}px;
	}
`

export const DataListHeading = styled.h3`
	margin-bottom: 8px;
	font-family: ${theme.fonts.overline};
	font-size: ${theme.typography.fontSizeOverline}px;
	font-weight: normal;
	color: ${theme.colors.white};
	letter-spacing: 0.5px;
	text-transform: uppercase;
`

export const DetailsTop = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
	gap: ${theme.spacing.x2}px;
	padding-bottom: ${theme.spacing.x3}px;

	dl {
		width: calc(50% - ${theme.spacing.x1}px);
	}

	@media ${theme.legacyMediaQueries.md} {
		padding-bottom: 48px;

		dl {
			width: calc(33.33% - ${theme.spacing.x2}px);
		}
	}
`

export const MobileToggle = styled.div<Toggled>`
	margin-bottom: 20px;

	button {
		color: ${theme.colors.white};

		svg {
			transform: ${({ toggled }) =>
				toggled
					? 'rotate(-90deg)!important'
					: 'rotate(90deg)!important'};
		}
	}

	@media ${theme.legacyMediaQueries.md} {
		display: none;
	}
`

export const DataListTitle = styled.dt`
	margin-bottom: 8px;
	font-family: ${theme.fonts.overline};
	font-size: ${theme.typography.fontSizeOverline}px;
	color: ${theme.colors.white};
	letter-spacing: 0.5px;
	text-transform: uppercase;
`

export const DataListTitleSmall = styled.dt`
	margin-bottom: 8px;
	font-family: ${theme.fonts.overline};
	font-size: ${theme.typography.fontSizeTextXSmall}px;
	color: ${theme.colors.white};
	line-height: 30px;
	letter-spacing: 0.5px;
	text-transform: uppercase;
`

export const DataListDescription = styled.dd`
	font-size: ${theme.typography.fontSizeTextSmall}px;
	color: ${theme.colors.white};
	letter-spacing: 0.2px;
	line-height: 30px;
`

export const DataListDescriptionSmall = styled.dd`
	font-size: ${theme.typography.fontSizeTextSmall}px;
	color: ${theme.colors.white};
	letter-spacing: 0.2px;
	line-height: 16px;
`

import { theme, Heading } from '@damen/ui'
import styled, { css } from 'styled-components'
import { Props, LeftProps, RightProps } from './types'

export const Title = styled(Heading.HeadingOne)`
	margin-bottom: 0;
`

export const PreTitle = styled(Heading.Overline)`
	margin-top: 40px;
	margin-bottom: 20px;
`

export const FlexInner = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 56px 0;

	@media ${theme.legacyMediaQueries.md} {
		flex-direction: row;
		padding: 96px 0 56px 0;
	}
`

export const Left = styled.div<LeftProps>`
	width: 100%;

	@media ${theme.legacyMediaQueries.md} {
		max-width: ${({ fullWidth }) => (fullWidth === true ? '100%' : '50%')};
		margin-right: 60px;

		${({ bannerLeftWidth }) =>
			bannerLeftWidth &&
			css`
				max-width: ${bannerLeftWidth};
			`}
	}

	@media ${theme.legacyMediaQueries.lg} {
		margin-right: 147px;
	}
`

export const Right = styled.div<RightProps>`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
	margin-top: 56px;

	@media ${theme.legacyMediaQueries.md} {
		align-items: flex-end;
		justify-content: flex-end;
		max-width: ${({ bannerRightWidth }) => bannerRightWidth ?? '50%'};
		margin-top: 0;
	}
`

export const IconWrapper = styled.div`
	margin-bottom: 16px;
`

export const BannerLeftChildrenWrapper = styled.div`
	margin-top: 48px;
`

export const Bottom = styled.div`
	margin-bottom: 36px;

	&:empty {
		margin-bottom: 0;
	}

	@media ${theme.legacyMediaQueries.md} {
		margin-bottom: 66px;
	}
`

export const Wrapper = styled.div<Props>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	transition: background 0.2s;
	background-color: transparent;

	${({ color }) =>
		color !== 'transparent' &&
		css`
			background-color: ${color === 'blue'
				? theme.colors.blue
				: theme.colors.white};
		`}

	&,
	a {
		color: ${({ color }) =>
			color === 'blue' ? theme.colors.white : theme.colors.marineBlack};
	}

	a {
		transition: color 0.2s;
	}
`

export const BottomIconWrapper = styled.div`
	display: none;

	@media ${theme.legacyMediaQueries.lg} {
		display: block;
		position: absolute;
		right: 0;
		bottom: -62px;
	}
`
